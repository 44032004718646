.db {
    display: block;
}

.dt {
    display: table;
}

.lh-copy {
    line-height: 1.5;
}

.mw6 {
    max-width: 32rem;
}

.mw7 {
    max-width: 48rem;
}

.w-100 {
    width: 100%;
}

.pb5 {
    padding-bottom: 4rem;
}

.pt0 {
    padding-top: 0;
}

.ph2 {
    padding-left: .5rem;
    padding-right: .5rem;
}

.center {
    margin-right: auto;
    margin-left: auto;
}

.v-mid {
    vertical-align: middle;
}

@media screen and (min-width: 30em) {
    .dtc-ns {
        display: table-cell;
    }

    .w5-ns {
        width: 16rem;
    }

    .pl3-ns {
        padding-left: 1rem;
    }

    .pr0-ns {
        padding-right: 0;
    }

    .pv6-ns {
        padding-top: 8rem;
        padding-bottom: 8rem;
    }

    .v-mid-ns {
        vertical-align: middle;
    }
}

@media screen and (min-width: 30em) and (max-width: 60em) {
    .pv5-m {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
}

.br2 {
    border-radius: .25rem;
}

.dib {
    display: inline-block;
}

.inline-flex {
    display: inline-flex;
}

.items-center {
    align-items: center;
}

.h2 {
    height: 2rem;
}

.w2 {
    width: 2rem;
}

.near-white {
    color: #f4f4f4;
}

.bg-near-black {
    background-color: #111;
}

.hover-bg-gray:hover {
    background-color: #777;
}

.hover-bg-gray:focus {
    background-color: #777;
}

.pa2 {
    padding: .5rem;
}

.pr2 {
    padding-right: .5rem;
}

.pv4 {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.ph2 {
    padding-left: .5rem;
    padding-right: .5rem;
}

.ma2 {
    margin: .5rem;
}

.ml3 {
    margin-left: 1rem;
}

.no-underline {
    text-decoration: none;
}

.tc {
    text-align: center;
}

.f6 {
    font-size: .875rem;
}

.bg-animate, .bg-animate:hover, .bg-animate:focus {
    transition: background-color .15s ease-in-out;
}

@media screen and (min-width: 60em) {
    .tc-l {
        text-align: center;
    }
}
