.villaDescription {
    text-align: left;
    margin-left: 2%;
    margin-right: 4%;
    font-size: 25px;
}

.button-margin {
    margin-bottom: 20px;
}
.button-33 {
    background-color: #FF5A5F;
    border-radius: 100px;
    box-shadow: rgba(44, 187, 99, .2) 0 -25px 18px -14px inset,rgba(44, 187, 99, .15) 0 1px 2px,rgba(44, 187, 99, .15) 0 2px 4px,rgba(44, 187, 99, .15) 0 4px 8px,rgba(44, 187, 99, .15) 0 8px 16px,rgba(44, 187, 99, .15) 0 16px 32px;
    color: white;
    cursor: pointer;
    display: flex;
    font-family: CerebriSans-Regular,-apple-system,system-ui,Roboto,sans-serif;
    padding: 7px 20px;
    text-align: center;
    text-decoration: none;
    transition: all 250ms;
    border: 0;
    font-size: 16px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  
  .button-33:hover {
    box-shadow: rgba(44,187,99,.35) 0 -25px 18px -14px inset,rgba(44,187,99,.25) 0 1px 2px,rgba(44,187,99,.25) 0 2px 4px,rgba(44,187,99,.25) 0 4px 8px,rgba(44,187,99,.25) 0 8px 16px,rgba(44,187,99,.25) 0 16px 32px;
    transform: scale(1.05) rotate(-1deg);
  }


  .image-gallery {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.image-gallery-slide img {
    width: 100%;
    height: auto;
    max-height: 80vh;
    object-fit: cover;
    overflow: hidden;
    object-position: center center;
}

.fullscreen .image-gallery-slide img {
    /* max-height: 100vh; */
    height: 70vh;
    padding-bottom: 30%;
    width: 100vw;
    object-position: center center;
}
  

.aspect-ratio {
    height: 0;
    position: relative;
}

.aspect-ratio--1x1 {
    padding-bottom: 100%;
}

.aspect-ratio--object {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.cover {
    background-size: cover !important;
}

.bg-center {
    background-repeat: no-repeat;
    background-position: center center;
}

.cf:before, .cf:after {
    content: " ";
    display: table;
}

.cf:after {
    clear: both;
}

.cf {
    *zoom: 1;
}

.db {
    display: block;
}

.fl {
    float: left;
    _display: inline;
}

.w-50 {
    width: 50%;
}

.w-100 {
    width: 100%;
}

@media screen and (min-width: 30em) {
    .w-25-ns {
        width: 25%;
    }
}

@media screen and (min-width: 30em) and (max-width: 60em) {
    .w-third-m {
        width: calc(100% / 3);
    }
}

.lh-copy {
    line-height: 1.5;
}

.pa3 {
    padding: 1rem;
}

.f4 {
    font-size: 1.25rem;
}

.measure {
    max-width: 30em;
}

@media screen and (min-width: 30em) {
    .pa5-ns {
        padding: 4rem;
    }
}

